import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
// import Container from 'react-bootstrap/Container';
import { inject, observer } from 'mobx-react';
import { useMediaQuery, useScroll } from '@react-hooks-library/core';
import { useFormContext } from 'react-hook-form';
// const RightSidePanel = (props: RightSidePanelProps) => {
const RightSidePanel = () => {
    // const { stateAppStore } = props;
    // const stateOrder = stateAppStore?.stateOrder ?? 0;
    const { setValue,
    // getValues
     } = useFormContext(); // retrieve all hook methods
    const box = useRef(null);
    useScroll(box, ({ scrollY }) => { setValue('scrollY', scrollY); });
    //  const values = getValues();
    const isMobile = useMediaQuery('(max-width: 768px)');
    /*   useEffect(() => {
      if (box?.current?.scrollTo) {
        box?.current?.scrollTo(0, 0);
      }
      setValue('scrollY', 0);
    }, [stateOrder]); */
    return (_jsx("div", { children: isMobile ? null
            : (_jsx("div", { className: 'right-side-panel', children: _jsx("a", { href: 'https://avtoapp.ru/legal/rulesofuse.html', children: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0435" }) })) }));
};
export default inject('stateAppStore')(observer(RightSidePanel));
