import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  Route, Routes,
  BrowserRouter,
} from 'react-router-dom';

import { App } from './App';

const routes = (
  <Routes>
    <Route path='/' element='Москва' />
    <Route path='/msk' element='Москва' />
    <Route path='/spb' element='Санкт-Петербург' />
    <Route path='/kazan' element='Казань' />
    <Route path='/sochi' element='Сочи' />
  </Routes>
);

const container = document.getElementById('evacuator-root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App tab='evacuator-root'>{ routes }</App>
  </BrowserRouter>
  // </React.StrictMode>
);

// ReactDOM.render(<App />, document.getElementById('evacuator-root'));
