import * as Yup from 'yup';
export const fillingPhone = Yup.object().shape({
    phoneNumber: Yup.string().required('Укажите телефон').min(12, 'Короткий телефон')
        .test({
        name: 'phoneNumber',
        exclusive: true,
        message: 'Некорректный номер',
        test: (value) => value.at(2) === '9',
    }),
});
export const fillingClientData = Yup.object().shape({
    firstName: Yup.string()
        .required('Укажите имя')
        .min(2, 'Слишком коротко')
        .matches(/^[а-яА-ЯёЁ-\s]+$/, 'Только русские буквы'),
    // lastName: Yup.string().required('Укажите фамилию').min(2, 'Слишком коротко').matches(/^[а-яА-ЯёЁ-\s]+$/, 'Только русские буквы'),
});
export const fillingOrderData = Yup.object().shape({
    address: Yup.string().required('Укажите, куда приехать'),
    deliveryAddress: Yup.string().required('Укажите, куда везём'),
    bodyTypeRefValueId: Yup.number().required('Выберите тип кузова'),
});
