var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { startOfToday, startOfDay, isEqual, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { inject, observer } from 'mobx-react';
import { useMemo, useState, } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import DefaultDatePicker, { registerLocale } from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';
import { MobileModalForm } from '../../../index';
import { iconSelector } from '../../../icons/iconSelector';
import { validateField } from '../../validation';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ru', ru);
const DatePicker = (_a) => {
    var { label, required, controlId, name, iconType, placeholder, validationSchema, shouldValidate, error, includeDates } = _a, props = __rest(_a, ["label", "required", "controlId", "name", "iconType", "placeholder", "validationSchema", "shouldValidate", "error", "includeDates"]);
    const { control, getValues } = useFormContext();
    const [isCalendarOpen, setOpen] = useState(false);
    const localError = useMemo(() => {
        var _a;
        return (_a = ((shouldValidate && validationSchema)
            ? validateField(name, getValues(), validationSchema)
            : null)) !== null && _a !== void 0 ? _a : error;
    }, [error, getValues, name, shouldValidate, validationSchema]);
    const todayButton = useMemo(() => {
        const todayDate = startOfToday();
        // eslint-disable-next-line no-restricted-syntax
        for (const date of includeDates) {
            if (isEqual(startOfDay(date), todayDate)) {
                return props.todayButton || 'Сегодня';
            }
        }
        return undefined;
    }, [includeDates, props.todayButton]);
    const inputGroupComponent = (field) => {
        return (_jsxs(InputGroup, { children: [_jsx(Form.Control, Object.assign({}, field, { readOnly: true, placeholder: label, onClick: () => {
                        setOpen(true);
                    }, value: field.value ? format(new Date(field.value), 'dd.MM.yyyy') : undefined, onChange: () => { }, className: `simple-edit ${localError ? 'error-component error-border error-color-font' : ''}` })), isCalendarOpen
                    ? (_jsx(MobileModalForm, { label: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0430\u0442\u0443', onClose: () => {
                            setOpen(false);
                        }, children: _jsx(DefaultDatePicker, Object.assign({}, props, { inline: true, locale: 'ru', className: `form-control simple-edit ${localError ? 'error-component error-border error-color-font' : ''}`, 
                            // Пока костыль для основной формы потом надо будет переделать
                            selected: field.value, onChange: (date) => {
                                field.onChange(date);
                                setOpen(false);
                            }, onFocus: (e) => {
                                e.target.readOnly = true;
                            }, dateFormat: props.dateFormat || 'dd.MM.yyyy', placeholderText: placeholder || 'Дата', includeDates: includeDates, todayButton: todayButton, popperPlacement: 'top-start' })) }))
                    : null] }));
    };
    return (_jsxs(Form.Group, { controlId: controlId, className: 'componentRow', children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${localError ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    if (iconType) {
                        return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                    }
                    return inputGroupComponent(field);
                } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: localError !== null && localError !== void 0 ? localError : ' ' }) })] }));
};
export default inject('referenceStore')(observer(DatePicker));
