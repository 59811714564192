var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import * as Highlighter from 'react-highlight-words';
import { useFormContext,
// ,Controller
 } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { SimpleInput } from '../index';
import { referenceActions } from '../../order/store/referenceStore';
const getHighlightWords = (query = '') => {
    const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];
    return (query === null || query === void 0 ? void 0 : query.replace) && query.replace(',', '').split(' ').filter((word) => {
        return wordsToPass.indexOf(word) < 0;
    });
};
const AddressSuggestionsInput = (props) => {
    // eslint-disable-next-line object-curly-newline
    const { onChange, name, referenceStore, namePlaceId, nameLat, nameLng } = props, rest = __rest(props, ["onChange", "name", "referenceStore", "namePlaceId", "nameLat", "nameLng"]);
    const token = 'a0520b85b5d07edeca6e4f75dd5430ee0034eb61';
    const { control, watch, setValue } = useFormContext();
    const values = watch();
    const [query, setQuery] = useState(values[name]);
    const [inputQuery, setInputQuery] = useState(values[name]);
    const [inputFocused, setInputFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(-1);
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const inputRef = useRef(null);
    const [placeId, setPlaceId] = useState('');
    const [focused, setFocused] = useState(false);
    const fetchAddressSuggestions = (data, callback) => __awaiter(void 0, void 0, void 0, function* () {
        const x = yield referenceActions.getGeoPlaces({ input: data === null || data === void 0 ? void 0 : data.query });
        const y = (v) => { var _a, _b; return ((_a = v === null || v === void 0 ? void 0 : v.subtitle) === null || _a === void 0 ? void 0 : _a.text) ? `, ${(_b = v === null || v === void 0 ? void 0 : v.subtitle) === null || _b === void 0 ? void 0 : _b.text}` : ''; };
        callback(x.map(v => { var _a, _b; return { id: (_a = v === null || v === void 0 ? void 0 : v.title) === null || _a === void 0 ? void 0 : _a.text, value: `${(_b = v === null || v === void 0 ? void 0 : v.title) === null || _b === void 0 ? void 0 : _b.text}${y(v)}` }; }));
    });
    const selectSuggestion = (index) => {
        var _a, _b, _c, _d, _e;
        if (suggestions.length >= index - 1) {
            setQuery((_a = suggestions[index]) === null || _a === void 0 ? void 0 : _a.value);
            setInputQuery((_b = suggestions[index]) === null || _b === void 0 ? void 0 : _b.value);
            setValue(name, (_c = suggestions[index]) === null || _c === void 0 ? void 0 : _c.value);
            if (namePlaceId) {
                setValue(namePlaceId, (_d = suggestions[index]) === null || _d === void 0 ? void 0 : _d.id);
                setPlaceId((_e = suggestions[index]) === null || _e === void 0 ? void 0 : _e.id);
            }
            setSuggestionsVisible(false);
            if (onChange) {
                onChange(suggestions[index]);
            }
        }
    };
    /*  useEffect(() => {
      inputRef?.current?.focus();
    }, [values[name]]); */
    const onKeyPress = (event) => {
        setInputFocused(true);
        if (event.which === 40) {
            // Arrow down
            event.preventDefault();
            const newSuggestionIndex = suggestionIndex + 1;
            if ((suggestionIndex < suggestions.length) && suggestions[newSuggestionIndex]) {
                setInputQuery(suggestions[newSuggestionIndex].value);
                setSuggestionIndex(newSuggestionIndex);
            }
        }
        else if (event.which === 38) {
            // Arrow up
            event.preventDefault();
            if (suggestionIndex >= 0) {
                const newSuggestionIndex = suggestionIndex - 1;
                setInputQuery(newSuggestionIndex === -1 ? query : suggestions[newSuggestionIndex].value);
                setSuggestionIndex(newSuggestionIndex);
            }
        }
        else if (event.which === 13) {
            // Enter
            event.preventDefault();
            if (suggestionIndex >= 0) {
                selectSuggestion(suggestionIndex);
            }
        }
    };
    const onSuggestionClick = (event, index) => {
        event.stopPropagation();
        selectSuggestion(index);
    };
    useEffect(() => {
        if ((values[name] && values[name] !== inputQuery) && inputFocused) {
            setSuggestionsVisible(true);
        }
        setQuery(values[name]);
        setInputQuery(values[name]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[name]]);
    useEffect(() => {
        fetchAddressSuggestions({ query }, (responseJson) => {
            if (responseJson) {
                setSuggestions(responseJson);
                setSuggestionIndex(-1);
            }
        });
        /*     if (values[name] !== query) {
          // setFieldValue(field.name, query);
        } else if (query) {
          fetchAddressSuggestions({ query }, (responseJson) => {
            if (responseJson && responseJson.suggestions) {
              setSuggestions(responseJson.suggestions);
              setSuggestionIndex(-1);
            }
          });
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
    const fetchAddressDirect = (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const x = yield referenceActions.getGeoPlaceDirect(request);
        const y = (_b = (_a = x === null || x === void 0 ? void 0 : x.response) === null || _a === void 0 ? void 0 : _a.GeoObjectCollection) === null || _b === void 0 ? void 0 : _b.featureMember;
        if (y && y.length > 0) {
            const z = (_d = (_c = y[0].GeoObject) === null || _c === void 0 ? void 0 : _c.Point) === null || _d === void 0 ? void 0 : _d.pos;
            const k = z.split(' ');
            if (k && k.length === 2) {
                if (nameLat) {
                    setValue(nameLat, Number(k[1]));
                }
                if (nameLng) {
                    setValue(nameLng, Number(k[0]));
                }
            }
            ;
        }
        ;
        // console.log('x=', x);
        // callback(x.map(v => { return { id: v.placeId, value: `${ v.secondaryText }, ${ v.mainText }` }; }));
    });
    useEffect(() => {
        var _a;
        if (namePlaceId
            && placeId
            && (((_a = referenceStore === null || referenceStore === void 0 ? void 0 : referenceStore.geoPlaceDirectRequest) === null || _a === void 0 ? void 0 : _a.address) !== placeId)) {
            fetchAddressDirect({ address: placeId });
        }
    }, [namePlaceId, placeId]);
    const clearValues = () => {
        setQuery('');
        setInputQuery('');
        setValue(name, '');
        setValue(nameLat, null);
        setValue(nameLng, null);
        setValue(namePlaceId, undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(SimpleInput, Object.assign({ name: name }, rest, { 
                // autoComplete={ autocomplete || 'off' }
                onKeyPress: onKeyPress, onKeyDown: onKeyPress, onFocus: () => setInputFocused(true), onBlur: () => {
                    setSuggestionsVisible(false);
                    // Далее Hack: С опозданием скрываю признак InputFocused, иначе клик по кнопке "Очистить" не отрабатывает
                    // setInputFocused(false);
                    setTimeout(() => setInputFocused(false), 300);
                }, onClear: inputFocused ? clearValues : null, ref: inputRef })), suggestions
                && inputFocused
                && suggestionsVisible
                && suggestions.length > 0
                && (_jsxs("div", { className: 'autocomplete__suggestions', children: [_jsx("div", { className: 'autocomplete__suggestion-note', children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0430\u0440\u0438\u0430\u043D\u0442 \u0438\u043B\u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u0435 \u0432\u0432\u043E\u0434" }), suggestions.map((suggestion, index) => {
                            let suggestionClass = 'autocomplete__suggestion';
                            if (index === suggestionIndex) {
                                suggestionClass += ' autocomplete__suggestion--current';
                            }
                            return (_jsx("div", { role: 'row', tabIndex: index, className: suggestionClass, onMouseDown: (event) => {
                                    onSuggestionClick(event, index);
                                    setTimeout(() => {
                                        var _a;
                                        if (suggestionsVisible) {
                                            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                                        }
                                    }, 10);
                                }, children: _jsx(Highlighter, { highlightClassName: 'autocomplete--highlighted', autoEscape: true, searchWords: getHighlightWords(inputQuery), textToHighlight: suggestion.value }) }, suggestion.id));
                        })] }))] }));
};
export default inject('referenceStore')(observer(AddressSuggestionsInput));
