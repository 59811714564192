import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { inject, observer } from 'mobx-react';
// import { useNavigate } from 'react-router-dom';
// import { useFormContext } from 'react-hook-form';
const PhonePanel = () => {
    // const { referenceStore } = props;
    // const navigate = useNavigate();
    return (_jsx("div", { className: 'phone-box', children: _jsx("div", { className: 'phone-layer', children: _jsxs("div", { className: 'align-items-center', children: [_jsx("div", { className: 'back' }), _jsx("div", { className: 'logo', children: _jsx("img", { src: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/evac-car.png', alt: '\u0421\u0440\u043E\u0447\u043D\u0430\u044F \u044D\u0432\u0430\u043A\u0443\u0430\u0446\u0438\u044F' }) }), _jsxs("div", { className: 'text-block', children: [_jsx("div", { className: 'label', children: "\u0417\u0430\u043A\u0430\u0437 \u043F\u043E \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443" }), _jsx("div", { className: 'phone', children: _jsx("a", { href: 'tel:+7 (499) 110-16-29', style: { textDecoration: 'none' }, children: "+7 (499) 110-16-29" }) })] })] }) }) }));
};
export default PhonePanel;
