var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { AsYouType } from 'libphonenumber-js';
import { useInterval, useMediaQuery } from '@react-hooks-library/core';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { PenIcon, SmsInput } from '../core';
import { authActions } from './store/authStore';
import ym from 'react-yandex-metrika';
import { clientActions } from './store/clientStore';
import UniversalModalForm from './UniversalModalForm';
const SmsPanelContent = (props) => {
    var _a;
    const { clientStore } = props;
    const { clientProfile } = clientStore;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const params = new URLSearchParams(window.location.search);
    const [countSeconds, setCountSeconds] = useState(60);
    const [confirmMessageError, setConfirmMessageError] = useState(null);
    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(false);
    const inputRef = useRef();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const repeatSms = useCallback(() => setCountSeconds((prev) => prev - 1), []);
    useInterval(repeatSms, 1000);
    const handleClose = () => {
        setValue('smsCode', null);
        stateAppActions.setAppStateOrder(0);
    };
    /* const handleChangePhone = () => {
      setValue('smsCode', null);
      stateAppActions.setAppStateOrder(1);
    }; */
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const translateError = (s) => {
        if (s === 'Wrong login or password') {
            return 'Неправильный код';
        }
        if (s === 'Password expired') {
            return 'Неправильный код';
        }
        return s;
    };
    const sendSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setValue('smsAlreadySend', true);
        setCountSeconds(60);
        /* if (!values?.captchaToken) {
          handleChangePhone();
        } */
        if (!params.get('nosms') && ((values === null || values === void 0 ? void 0 : values.captchaToken) || token)) {
            yield authActions.sendSms({
                phoneNumber: values.phoneNumber,
                fcmToken: 'empty',
                captchaToken: (_a = values === null || values === void 0 ? void 0 : values.captchaToken) !== null && _a !== void 0 ? _a : token,
            });
            setValue('captchaToken', null);
            setToken(null);
            setVisible(false);
        }
    });
    /* const createOrder = async () => {
      if (!values?.orderId) {
        // Обрати внимание - если id заявки уже есть, повторно не создаём
        const createOrderResponse: CreateOrderResponseInterface = await orderActions.createOrderProc(values);
        if (createOrderResponse.id) {
          stateAppActions.setAppStateOrder(4);
        }
      }
    }; */
    const formatPhone = () => {
        var _a;
        if (values === null || values === void 0 ? void 0 : values.phoneNumber) {
            const asYouType = new AsYouType('RU');
            asYouType.input((_a = values === null || values === void 0 ? void 0 : values.phoneNumber) !== null && _a !== void 0 ? _a : '');
            return asYouType.getNumber().formatInternational();
        }
        return '';
    };
    const confirmSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const confirmSms = yield authActions.confirmSms({
            phoneNumber: values.phoneNumber,
            password: values === null || values === void 0 ? void 0 : values.smsCode,
            projectId: '8f3be8a7-dcfc-4f3b-be03-71b0e7d19e5e',
        });
        if (confirmSms.token) {
            ym('reachGoal', 'confirm_an_continue');
        }
        setValue('token', (_a = confirmSms.token) !== null && _a !== void 0 ? _a : null);
        setConfirmMessageError((_b = confirmSms.message) !== null && _b !== void 0 ? _b : null);
        // setCountSecondsError(7);
    });
    useEffect(() => {
        if (!(values === null || values === void 0 ? void 0 : values.orderId) && (values === null || values === void 0 ? void 0 : values.token)) {
            clientActions.getClient(values === null || values === void 0 ? void 0 : values.token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values === null || values === void 0 ? void 0 : values.token]);
    useEffect(() => {
        var _a;
        if (!(values === null || values === void 0 ? void 0 : values.orderId)
            // && clientProfile?.firstName
            && (clientProfile === null || clientProfile === void 0 ? void 0 : clientProfile.clientId)) {
            // createOrder();
            setValue('firstName', (_a = clientProfile === null || clientProfile === void 0 ? void 0 : clientProfile.firstName) !== null && _a !== void 0 ? _a : '');
            stateAppActions.setAppStateOrder(4);
        }
        /* if (
          !values?.orderId
          && !clientProfile?.firstName
          && clientProfile?.clientId
        ) {
          setValue('firstName', clientProfile?.firstName);
          setValue('lastName', clientProfile?.lastName);
          stateAppActions.setAppStateOrder(3);
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = clientStore === null || clientStore === void 0 ? void 0 : clientStore.clientProfile) === null || _a === void 0 ? void 0 : _a.clientId]);
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.smsCode) && (values === null || values === void 0 ? void 0 : values.smsCode.length) === 4) {
            confirmSMS();
            // handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values === null || values === void 0 ? void 0 : values.smsCode]);
    useEffect(() => {
        // TODO -  if (!values?.token) { - для отладки добавил, вырезать
        // данная проверка позволяет оформить повторный заказ без ввода СМС. Верно или нет ? Пока не понятно.
        if (!(values === null || values === void 0 ? void 0 : values.token) && (values === null || values === void 0 ? void 0 : values.phoneNumber) && !(values === null || values === void 0 ? void 0 : values.smsAlreadySend)) {
            sendSMS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (token) {
            setValue('captchaToken', token);
            sendSMS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    const xx = () => {
        return (_jsxs(_Fragment, { children: [isMobile && (_jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'font-14-400-black-noSpacing', children: "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u043D\u0430 \u043D\u043E\u043C\u0435\u0440:" }) })), _jsx(Row, { className: 'phone_line', children: _jsxs(Col, { className: 'phone_direction', onClick: () => {
                            stateAppActions.setAppStateOrder(1);
                        }, children: [!isMobile && _jsx("div", { className: 'font-14-400-black-noSpacing', children: "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u043D\u0430 \u043D\u043E\u043C\u0435\u0440: " }), _jsx("div", { className: 'font-14-800-black', children: formatPhone() }), _jsxs("div", { children: [" ", _jsx(PenIcon, {}), " "] }), _jsx("div", { className: 'edit-phone', children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })] }) })] }));
    };
    return (_jsx(UniversalModalForm, { headerText: '\u0412\u043E\u0439\u0442\u0438 \u0434\u043B\u044F \u0437\u0430\u043A\u0430\u0437\u0430', detailHeader: xx(), handleClose: handleClose, children: _jsx(Row, { children: _jsxs("div", { className: 'sms_line sms_line_reverse', children: [_jsxs("div", { className: 'sms_div', children: [_jsx("div", { className: 'input-block', children: _jsx(SmsInput, { label: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434', name: 'smsCode', error: translateError(confirmMessageError), ref: inputRef }) }), _jsxs("div", { className: 'repeat-button', children: [countSeconds > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'label', children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsxs("div", { className: 'seconds', children: [countSeconds, " \u0441\u0435\u043A"] })] })), countSeconds <= 0 && (_jsx(Button, { className: 'yellow-button  yellow-not-disabled', variant: 'primary', onClick: () => {
                                            if (token) {
                                                sendSMS();
                                            }
                                            else {
                                                setVisible(true);
                                            }
                                        }, children: "\u041E\u0422\u041F\u0420\u0410\u0412\u0418\u0422\u042C \u0421\u041C\u0421" }))] })] }), _jsx("div", { className: 'repeat', children: _jsx(Button, { className: 'yellow-button  disabledButton', variant: 'primary', disabled: true, children: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }) }), visible && (_jsx(InvisibleSmartCaptcha, { sitekey: process.env.YANDEX_CAPTCHA_KEY, onSuccess: setToken, onChallengeHidden: handleChallengeHidden, visible: visible }))] }) }) }));
};
export default inject('clientStore')(observer(SmsPanelContent));
