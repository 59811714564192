import { jsx as _jsx } from "react/jsx-runtime";
import { Men, Calendar, Clock, Phone, NumberIcon, MiniPinIcon, AutoIcon, } from '..';
export const iconSelector = (iconType) => {
    switch (iconType) {
        case 'men':
            return (_jsx(Men, {}));
        case 'calendar':
            return (_jsx(Calendar, {}));
        case 'phone':
            return (_jsx(Phone, {}));
        case 'clock':
            return (_jsx(Clock, {}));
        case 'number':
            return (_jsx(NumberIcon, {}));
        case 'auto':
            return (_jsx(AutoIcon, {}));
        case 'miniPin':
            return (_jsx(MiniPinIcon, {}));
        default:
            return null;
    }
};
