import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Provider } from 'mobx-react';
import { YMInitializer } from 'react-yandex-metrika';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
import * as stores from './lib/mobx/stores';
import PrimaryPanel from './modules/layers/PrimaryPanel';
import './scss/main.scss';
setDefaultOptions({ locale: ru });
/* type typeProps = {
  tab: string,
  children: ReactNode,
} */
const App = (props) => {
    return (_jsx(Provider, Object.assign({}, stores, { children: _jsxs("div", { className: 'main_container', children: [_jsx(YMInitializer, { accounts: [96933750] }), _jsxs(PrimaryPanel, { children: [" ", props === null || props === void 0 ? void 0 : props.children, " "] })] }) })));
};
export { App };
