import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { faRuble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { Bonus, EvacuatorIcon, ManipulatorIcon } from '..';
const PriceBox = (props) => {
    const { priceText, label, bonus, serviceId, disabled, hidePrice, } = props;
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    const handlePriceBoxClick = () => {
        if (((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 343) || ((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 344)) {
            setValue('serviceId', 42);
        }
        else {
            setValue('serviceId', serviceId);
        }
    };
    const evacuator = disabled ? _jsx(EvacuatorIcon, { color: 'var(--gray-color-font)' }) : _jsx(EvacuatorIcon, {});
    const manipulator = disabled ? _jsx(ManipulatorIcon, { color: 'var(--gray-color-font)' }) : _jsx(ManipulatorIcon, {});
    return (_jsxs("div", { className: `price-box${(values === null || values === void 0 ? void 0 : values.serviceId) === serviceId ? ' selected_item_box' : ''}${disabled ? ' price-box-disabled' : ''}${hidePrice ? ' price-box-no-price' : ' price-box-full'}`, "aria-hidden": 'true', onClick: handlePriceBoxClick, children: [_jsx("div", { className: 'box-1', children: serviceId === 13 ? evacuator : manipulator }), _jsx("div", { className: 'box-2', children: label }), _jsxs("div", { children: [!hidePrice
                        && (_jsxs("div", { className: 'box-3', children: [priceText, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '0px', paddingTop: '0px' } })] })), !hidePrice
                        && (_jsx("div", { className: 'box-4', children: _jsx("div", { children: _jsx(Bonus, { bonus: bonus, disabled: disabled }) }) }))] })] }));
};
export default inject('calculatePriceStore')(observer(PriceBox));
