var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
ArrowRightIcon, ExpandArrowIcon, } from '..';
import BodyTypeSelectorModal from './BodyTypeSelectorModal';
import { referenceActions } from '../../order/store/referenceStore';
import { iconSelector } from '../icons/iconSelector';
import { validateField } from '../components/validation';
const BodyTypeSelector = (props) => {
    var _a;
    const { label, required, error, controlId, name, iconType, placeholder, validationSchema, shouldValidate, } = props;
    const { watch } = useFormContext();
    const values = watch();
    const [showOptions, setShowOptions] = useState(false);
    const { control, getValues } = useFormContext();
    const [ref78, setRef78] = useState([]);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        const ref78Local = yield referenceActions.getRef(78);
        setRef78(ref78Local);
    });
    useEffect(() => {
        loadData();
    }, []);
    const handleClose = () => {
        setShowOptions(false);
    };
    const visibleName = (_a = ref78.find((v) => v.id === values[name])) === null || _a === void 0 ? void 0 : _a.name;
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const inputGroupComponent = (field) => {
        return (_jsx(Row, { className: 'box_row', onClick: (event) => {
                event.preventDefault();
                setShowOptions((prev) => !prev);
            }, children: _jsxs(Col, { className: `selector_box selected_box${!visibleName ? ' selector_box_placeholder' : ''}${errorLocal ? ' error-component error-border error-color-font' : ''}`, children: [visibleName !== null && visibleName !== void 0 ? visibleName : placeholder, !showOptions
                        ? _jsx(ExpandArrowIcon, {})
                        : _jsx(ArrowRightIcon, {})] }) }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Form.Group, { controlId: controlId, children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${(error !== null && error !== void 0 ? error : errorLocal) ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                            if (iconType) {
                                return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                            }
                            return inputGroupComponent(field);
                        } }), (error !== null && error !== void 0 ? error : errorLocal) && (_jsx("div", { className: 'error-container', children: _jsx("div", { children: error !== null && error !== void 0 ? error : errorLocal }) }))] }), showOptions
                && (_jsx(BodyTypeSelectorModal
                // options={ options }
                , { 
                    // options={ options }
                    name: name, label: label, iconType: iconType, onClose: handleClose }))] }));
};
export default inject('referenceStore')(observer(BodyTypeSelector));
