import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
// import { useMediaQuery } from '@react-hooks-library/core';
import { faRuble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMonth, getDate, fromUnixTime } from 'date-fns';
import { useMediaQuery } from '@react-hooks-library/core';
const SummaryPricePanel = () => {
    var _a;
    const { watch } = useFormContext();
    const values = watch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const months = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ];
    const dateX = fromUnixTime(values === null || values === void 0 ? void 0 : values.callTime);
    const dateX1 = months[getMonth(dateX)];
    const dateX2 = getDate(dateX);
    const carType = ((_a = values === null || values === void 0 ? void 0 : values.serviceId) !== null && _a !== void 0 ? _a : 0) === 42 ? 'манипулятора' : 'эвакуатора';
    return (_jsxs("div", { className: 'price-block', children: [(values === null || values === void 0 ? void 0 : values.cost) && !isMobile
                && (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'left', children: [_jsx("div", { className: 'font-12-500-middle-gray-dark', children: "\u041F\u0440\u0438\u0435\u0445\u0430\u0442\u044C" }), _jsx("div", { className: 'price-line', children: (values === null || values === void 0 ? void 0 : values.urgentRefValueId) === 41 ? `${dateX2} ${dateX1} в ${values.hourValue}:${values.minuteValue}` : 'Как можно скорее' })] }), _jsxs("div", { className: 'right', children: [_jsx("div", { className: 'font-12-500-middle-gray-dark', children: "\u0421\u0443\u043C\u043C\u0430 \u0437\u0430\u043A\u0430\u0437\u0430" }), _jsxs("div", { className: 'price-line', children: ["\u043E\u0442 ", values === null || values === void 0 ? void 0 : values.cost, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '4px', paddingTop: '0px' } })] })] })] })), (values === null || values === void 0 ? void 0 : values.cost) && isMobile
                && (_jsxs("div", { className: 'full', children: [_jsx("div", { className: 'font-17-700 mobile first', children: `Приехать: ${(values === null || values === void 0 ? void 0 : values.urgentRefValueId) === 41
                                ? `${dateX2} ${dateX1} в ${values.hourValue}:${values.minuteValue}`
                                : 'Как можно скорее'} ` }), _jsxs("div", { className: 'font-17-700 mobile', children: [`Сумма заказа от ${values === null || values === void 0 ? void 0 : values.cost}`, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '4px', paddingTop: '0px' } })] })] })), !(values === null || values === void 0 ? void 0 : values.cost)
                && (_jsxs("div", { className: 'full', children: [_jsx("div", { className: 'font-17-700', children: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0443\u0442\u043E\u0447\u043D\u0438\u0442 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C" }), _jsxs("div", { className: 'font-17-700', children: ["\u0438 \u0432\u0440\u0435\u043C\u044F \u043F\u0440\u0438\u0431\u044B\u0442\u0438\u044F ", carType] })] }))] }));
};
export default SummaryPricePanel;
