var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { RefValueSelector } from '..';
const UrgentSelector = (props) => {
    const { name } = props, rest = __rest(props, ["name"]);
    // const [lastValue, setLastValue] = useState<number>(null);
    // const [showDateTimeSelectorModal, setShowDateTimeSelectorModal] = useState<boolean>(false);
    const { watch, setValue } = useFormContext();
    const values = watch();
    // const isMobile = useMediaQuery('(max-width: 768px)');
    /*
    useEffect(() => {
      if ((values[name] !== lastValue)) {
        setLastValue(values[name]);
        if (values[name] === 41) {
          setShowDateTimeSelectorModal(true);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[name]]);
  
    const handleClose = (isTimeSelected: boolean = false) => {
      if (!isTimeSelected) {
        setValue(name, 40);
      }
      setShowDateTimeSelectorModal(false);
    }; */
    return (_jsx(_Fragment, { children: _jsx(RefValueSelector, Object.assign({}, rest, { name: name })) }));
};
export default inject('referenceStore')(observer(UrgentSelector));
