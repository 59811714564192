import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
const BoxSelector = (props) => {
    const { label, options, required, error, controlId, name, } = props;
    const { control, setValue } = useFormContext();
    return (_jsxs(Form.Group, { controlId: controlId, children: [label && (_jsx(Form.Label, { className: `label mb-0 pt-1 ${required ? 'required' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    return (_jsx(Row, { children: _jsx(Col, { children: _jsx(Row, { className: 'box_selector_row', children: options.map((v) => {
                                    return (_jsx(Col, { className: `text-center d-flex count_box ${field.value === v.value ? 'selected_item_box' : ''}`, onClick: () => setValue(name, v.value), children: _jsxs(_Fragment, { children: [(v === null || v === void 0 ? void 0 : v.icon) && (_jsx("img", { src: v === null || v === void 0 ? void 0 : v.icon, alt: v.label })), v.label] }) }, v.value));
                                }) }) }) }));
                } }), error && (_jsx("div", { className: 'error-container', children: _jsx("div", { children: error }) }))] }));
};
export default inject('referenceStore')(observer(BoxSelector));
