var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import orderController from './controller';
export const calculatePriceStore = observable({
    priceRequest: null,
    priceResponse: null,
    error: null,
});
export const calculatePriceActions = {
    calculatePrice: (request) => __awaiter(void 0, void 0, void 0, function* () {
        // TODO - надо придумать, как получать значения по умолчанию
        const priceFrom = {
            manipulatorPrice: 2500,
            evacuatorPrice: 1500,
            manipulatorBonusReceivingPercent: 10,
            evacuatorBonusReceivingPercent: 10,
        };
        runInAction(() => {
            calculatePriceStore.priceRequest = request;
        });
        if ((request.startLat > 0)
            && (request.startLng > 0)
            && (request.endLng > 0)
            && (request.endLat > 0)) {
            const response = yield orderController.calculatePrice(request);
            /* if (response.errorData) {
              calculatePriceActions.clearPrice();
            } */
            calculatePriceActions.handleError(response);
            if (!response.errorData) {
                calculatePriceActions.clearError();
                calculatePriceStore.priceResponse = response.data;
                return response.data;
            }
        }
        calculatePriceStore.priceResponse = priceFrom;
        return priceFrom;
    }),
    clearPrice: () => __awaiter(void 0, void 0, void 0, function* () {
        calculatePriceStore.priceRequest = null;
        calculatePriceStore.priceResponse = null;
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            calculatePriceStore.error = errorData;
            // throw errorData;
        }
    }),
    clearError: action(() => {
        if (calculatePriceStore.error) {
            calculatePriceStore.error = null;
        }
    }),
};
