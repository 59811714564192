import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import { CloseIcon, ReturnIcon, MenuIcon } from '..';
import { stateAppActions } from '../../../lib/mobx/stateAppStore';
const Header = (props) => {
    const { returnNumber, header, noNavigate, gradient, onCloseClick, noReturn, shadow, showLinkMenu, } = props;
    const [showMenu, setShowMenu] = useState(false);
    // console.log('values = ', values?.scrollY);
    const handleClick = () => {
        if (onCloseClick) {
            onCloseClick();
        }
        if (returnNumber || returnNumber === 0) {
            stateAppActions.setAppStateOrder(returnNumber);
        }
    };
    return (_jsxs("div", { className: classNames('header', 'padding_main_layer', { shadow }), children: [_jsx(Row, { children: _jsxs(Col
                //  noReturn ? 12 : 11
                , { 
                    //  noReturn ? 12 : 11
                    xs: 12, className: `header_text${gradient ? ' avtoapp_color_font_20_gradient' : ' avtoapp_color_header_modal_font_22'}`, children: [!noNavigate && !noReturn
                            && (_jsx("div", { className: 'pull-left header_button', children: _jsx(ReturnIcon, { onClick: handleClick }) })), showLinkMenu && (_jsx(MenuIcon, { onClick: () => { setShowMenu(!showMenu); } })), header, !noNavigate
                            && (_jsx("div", { className: 'pull-right header_button header_button_close', children: _jsx(Row, { onClick: handleClick, children: _jsx("div", { className: 'header_button_close_icon', children: _jsx(CloseIcon, { onClick: handleClick }) }) }) }))] }) }), showMenu
                && (_jsx(Dropdown, { onToggle: () => setShowMenu(false), children: _jsxs(Dropdown.Menu, { show: showMenu, 
                        // align={ { sm: 'start' } }
                        className: 'links', children: [_jsxs(Dropdown.Item, { href: 'https://kolesa.avtoapp.ru/', children: [_jsx("div", { className: 'box-1', children: _jsx("img", { src: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/tirefitting.png', alt: '\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435' }) }), _jsx("div", { className: 'box-2', children: "\u0428\u0438\u043D\u043E\u043C\u043E\u043D\u0442\u0430\u0436" })] }), _jsxs(Dropdown.Item, { href: 'https://kolesa.avtoapp.ru/', children: [_jsx("div", { className: 'box-1', children: _jsx("img", { src: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/storage.png', alt: '\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435' }) }), _jsx("div", { className: 'box-2', children: "\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D" })] })] }) }))] }));
};
export default Header;
