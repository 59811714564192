var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable } from 'mobx';
import sortBy from 'lodash/sortBy';
import orderController from './controller';
export const referenceStore = observable({
    ref56: [],
    refs: [],
    regions: [],
    geoPlaces: [],
    geoPlacesRequest: null,
    geoPlaceDirect: null,
    geoPlaceDirectRequest: null,
    error: null,
});
export const referenceActions = {
    getRef: (refId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const sortFunc = (arr) => {
            return sortBy(arr, (e) => {
                var _a;
                return (_a = e.sort) !== null && _a !== void 0 ? _a : 0;
            });
        };
        const refs = (_a = referenceStore.refs) !== null && _a !== void 0 ? _a : [];
        const ref = (_b = refs.find(v => v.refId === refId)) === null || _b === void 0 ? void 0 : _b.ref;
        if (ref) {
            return ref;
        }
        const response = yield orderController.getUniversalReference(refId);
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.refs = [...referenceStore.refs, { ref: sortFunc((_c = response.data) !== null && _c !== void 0 ? _c : []), refId }].sort();
        }
        return sortFunc((_d = response.data) !== null && _d !== void 0 ? _d : []);
    }),
    getRegions: () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield orderController.getRegions();
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.regions = response.data.items;
        }
        /* referenceStore.regions = [
          {
            'fiasId': '26a25efa-4f63-4d4b-8db6-dfc29cdaab24',
            'region': 'Москва',
            'lat': 55.755864,
            'lng': 37.617698,
          },
          {
            'fiasId': 'c2deb16a-0330-4f05-821f-1d09c93331e6',
            'region': 'Санкт-Петербург',
            'lat': 59.938955,
            'lng': 30.315644,
          },
        ]; */
        return referenceStore.regions;
    }),
    getGeoPlaces: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        referenceStore.geoPlacesRequest = request;
        if (request.input) {
            const response = yield orderController.getGeoPlaces(request);
            referenceActions.handleError(response);
            if (!response.errorData) {
                referenceStore.geoPlaces = (_a = response.data) !== null && _a !== void 0 ? _a : [];
            }
            return (_b = response.data) !== null && _b !== void 0 ? _b : [];
        }
        return [];
    }),
    getGeoPlaceDirect: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        referenceStore.geoPlaceDirectRequest = request;
        const response = yield orderController.getGeoPlaceDirect(request);
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.geoPlaceDirect = (_a = response.data) !== null && _a !== void 0 ? _a : [];
        }
        return (_b = response.data) !== null && _b !== void 0 ? _b : {};
    }),
    getGeoPlaceReverse: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // referenceStore.geoPlaceDirectRequest = request;
        const response = yield orderController.getGeoPlaceReverse(request);
        /* referenceActions.handleError(response);
        if (!response.errorData) {
          referenceStore.geoPlaceDirect = response.data ?? [];
        } */
        return (_a = response.data[0]) !== null && _a !== void 0 ? _a : {};
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            referenceStore.error = errorData;
            throw errorData;
        }
    }),
    clearUR: action(() => {
        referenceStore.ref56 = [];
    }),
    clearError: action(() => {
        referenceStore.error = null;
    }),
};
