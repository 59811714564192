import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { PriceComponent } from '..';
const PriceComponentRow = (props) => {
    var _a;
    const { calculatePriceStore } = props;
    const { error } = calculatePriceStore;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    // const { getValues } = useFormContext();
    // const values = getValues();
    const carType = ((_a = values === null || values === void 0 ? void 0 : values.serviceId) !== null && _a !== void 0 ? _a : 0) === 42 ? 'манипулятора' : 'эвакуатора';
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'priceComponentsRow', children: [_jsx("div", { children: _jsx(PriceComponent, { label: '\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440', serviceId: 13 }) }), _jsx("div", { children: _jsx(PriceComponent, { label: '\u041C\u0430\u043D\u0438\u043F\u0443\u043B\u044F\u0442\u043E\u0440', serviceId: 42 }) })] }), !!(error === null || error === void 0 ? void 0 : error.errorCode)
                && (_jsxs("div", { className: 'text-under-prices', children: [_jsx("div", { className: 'text-under-prices-line', children: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0443\u0442\u043E\u0447\u043D\u0438\u0442 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C" }), _jsxs("div", { className: 'text-under-prices-line second-line', children: ["\u0438 \u0432\u0440\u0435\u043C\u044F \u043F\u0440\u0438\u0431\u044B\u0442\u0438\u044F ", carType] })] })), (error === null || error === void 0 ? void 0 : error.message) && (error === null || error === void 0 ? void 0 : error.errorCode) !== 'IMPOSSIBLE_ACTION'
                && (_jsx("div", { className: 'price-calculate-error', children: error === null || error === void 0 ? void 0 : error.message }))] }));
};
export default inject('calculatePriceStore')(observer(PriceComponentRow));
