import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery } from '@react-hooks-library/core';
import Offcanvas from 'react-bootstrap/Offcanvas';
const OffCanvas = (props) => {
    const { onClose, } = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => { if (onClose)
        onClose(); };
    return (_jsxs(Offcanvas, { show: true, onHide: handleClose, scroll: false, backdrop: true, placement: isMobile ? null : 'end', children: [_jsx(Offcanvas.Header, { closeButton: true, children: _jsx(Offcanvas.Title, { children: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F" }) }), _jsx(Offcanvas.Body, { children: _jsx("iframe", { title: '\u0423\u0441\u043B\u043E\u0432\u0438\u044F', src: 'https://avtoapp.ru/legal/rulesofuse.html', children: "\u0412\u0430\u0448 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043D\u0435 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442 \u0444\u0440\u0435\u0439\u043C\u044B!" }) })] }));
};
export default OffCanvas;
