export const bonusPercentByType = (x) => {
    return (x !== null && x !== void 0 ? x : 0) / 100;
};
export const calcLockedWheels = (x) => {
    switch (x) {
        case 55:
            return '0';
        case 56:
            return '1';
        case 342:
            return '2';
        case 343:
            return '3';
        case 345:
            return '4';
        default:
            return '0';
    }
};
export const paramsPriceObj = (values) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        bodyTypeRefValueId: (_a = values.bodyTypeRefValueId) !== null && _a !== void 0 ? _a : 364, // по умолчанию ставим седан
        endLat: (_b = values.deliveryLatitude) !== null && _b !== void 0 ? _b : 0,
        endLng: (_c = values.deliveryLongitude) !== null && _c !== void 0 ? _c : 0,
        lockedWheels: (_d = calcLockedWheels(values === null || values === void 0 ? void 0 : values.wheelLockRefValueId)) !== null && _d !== void 0 ? _d : '0', // TODO -  почему  тут строка ?
        startLat: (_e = values.lat) !== null && _e !== void 0 ? _e : 0,
        startLng: (_f = values.lng) !== null && _f !== void 0 ? _f : 0,
        // Параметров ниже у нас пока нет в виджете:
        // vehicleId?: ....,
        // vehicleTypeRefValueId?: ...,
    };
};
