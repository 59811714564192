/* eslint-disable no-console */
import moment from 'moment';

moment.locale('ru');

// для доступа к API подменяем часть домена до первой точки на api. Кузнецов Г.Л., 07.05.2020. Таск 3250
const $apiUrl = process.env.API_URL === 'location_host'
  ? window.location.origin
  : process.env.API_URL;

console.log('> NAME::', process.env.APP_NAME);
console.log('> VERSION::', process.env.APP_VERSION);
console.log('> SERVER::', $apiUrl);
console.log('> NODE_ENV::', process.env.NODE_ENV);
console.log('> DEBUG::', process.env.DEBUG);

const appConfig = () => ({
  name: process.env.APP_NAME,
  version: process.env.APP_VERSION,
  serverAddress: $apiUrl,
  connectivityError: 'Возникла ошибка сети. Проверьте соединение с интернет.',
  errorHdr: 'Возникла ошибка',
  successHdr: 'Успех',
  successSaveMsg: 'Изменения сохранены',
  page403Text: 'У вас недостаточно прав для доступа к этой странице.',
  error_to_email_text: 'Попробуйте ещё раз. В случае повторения ошибки отправьте текст ошибки на Email: support@avtoapp.ru',
  error_to_email: 'support@avtoapp.ru',
});

export default appConfig();
