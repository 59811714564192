import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import icon from './switchOn.svg';
/*
   // Обрати внимание - в строке
   <rect y='0.5' width='50' height='30' rx='15' fill='#722BF5' />
   параметр fill='#722BF5'
   вынес в scss, чтоб управлять hover-эффектами
*/
export const SwitchOnSvgComponent = () => {
    return (_jsxs("svg", { width: '51', height: '33', viewBox: '0 0 51 33', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("rect", { y: '0.5', width: '50', height: '30', rx: '15' }), _jsx("g", { filter: 'url(#filter0_dd_16950_197146)', children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M35 29.5C42.732 29.5 49 23.232 49 15.5C49 7.76801 42.732 1.5 35 1.5C27.268 1.5 21 7.76801 21 15.5C21 23.232 27.268 29.5 35 29.5Z', fill: 'white' }) }), _jsx("defs", { children: _jsxs("filter", { id: 'filter0_dd_16950_197146', x: '19', y: '0.5', width: '32', height: '32', filterUnits: 'userSpaceOnUse', colorInterpolationFilters: 'sRGB', children: [_jsx("feFlood", { floodOpacity: '0', result: 'BackgroundImageFix' }), _jsx("feColorMatrix", { in: 'SourceAlpha', type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0', result: 'hardAlpha' }), _jsx("feOffset", { dy: '1' }), _jsx("feGaussianBlur", { stdDeviation: '1' }), _jsx("feColorMatrix", { type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' }), _jsx("feBlend", { mode: 'normal', in2: 'BackgroundImageFix', result: 'effect1_dropShadow_16950_197146' }), _jsx("feColorMatrix", { in: 'SourceAlpha', type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0', result: 'hardAlpha' }), _jsx("feOffset", { dy: '0.1' }), _jsx("feGaussianBlur", { stdDeviation: '0.15' }), _jsx("feColorMatrix", { type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' }), _jsx("feBlend", { mode: 'normal', in2: 'effect1_dropShadow_16950_197146', result: 'effect2_dropShadow_16950_197146' }), _jsx("feBlend", { mode: 'normal', in: 'SourceGraphic', in2: 'effect2_dropShadow_16950_197146', result: 'shape' })] }) })] }));
};
export default icon;
