var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { referenceActions } from '../../order/store/referenceStore';
import { Select } from '..';
const RefValueSelector = (props) => {
    const { refId, label, error, required, name, placeholder } = props, rest = __rest(props, ["refId", "label", "error", "required", "name", "placeholder"]);
    const { watch } = useFormContext();
    const values = watch();
    const [localRef, setLocalRef] = useState(null);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!localRef) {
            const ref = yield referenceActions.getRef(refId); // ?  referenceStore?.ref56 ?? [];
            setLocalRef(ref);
        }
    });
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const options = (localRef !== null && localRef !== void 0 ? localRef : []).map(v => { return { label: v.name, value: v.id }; });
    if ((refId === 14) && options && (options.length > 0)) {
        options.find(v => v.value === 40).label = 'Как можно скорее';
        options.find(v => v.value === 41).label = (values === null || values === void 0 ? void 0 : values.urgentRefValueId) === 41 ? values === null || values === void 0 ? void 0 : values.dateString : 'Другое время';
    }
    return (_jsx(Select, Object.assign({}, rest, { label: label, error: error, required: required, options: options, name: name, placeholder: placeholder })));
};
export default inject('referenceStore')(observer(RefValueSelector));
