import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { format, addDays, parse, getUnixTime, } from 'date-fns';
import { Select, } from '..';
import { validateField } from '../components/validation';
import { iconSelector } from '../icons/iconSelector';
const DateTimeSelector = (props) => {
    const { watch, setValue } = useFormContext();
    const values = watch();
    const { getValues } = useFormContext();
    const { name, label, controlId, error, required, iconType, validationSchema, shouldValidate, } = props;
    const createTimeOptions = (len, step) => {
        const x = [];
        for (let index = 0; index < len; index++) {
            x.push({ value: (index * step).toString().padStart(2, '0'), label: (index * step).toString().padStart(2, '0') });
        }
        return x;
    };
    // Локаль для дат ( название месяца) задаётся тут кодом
    // setDefaultOptions({ locale: ru });
    // Ищи в коде
    const date = new Date();
    const optionsDate = [
        { value: format(date, 'yyyy-MM-dd'), label: 'сегодня' },
        { value: format(addDays(date, 1), 'yyyy-MM-dd'), label: `${format(addDays(date, 1), 'dd')} ${format(addDays(date, 1), 'MMM')},${format(addDays(date, 1), 'EEEEEE')}` },
        { value: format(addDays(date, 2), 'yyyy-MM-dd'), label: `${format(addDays(date, 2), 'dd')} ${format(addDays(date, 2), 'MMM')},${format(addDays(date, 2), 'EEEEEE')}` },
    ];
    const optionsMinutes = createTimeOptions(12, 5);
    const optionsHours = createTimeOptions(24, 1);
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema)
        ? validateField(name, getValues(), validationSchema) : null);
    /* const handleClose = () => {
      const newDate = parse(`${ values.dateValue }`, 'yyyy-MM-dd', new Date());
      setValue('dateString', `${ values.hourValue }:${ values.minuteValue }, ${ format(newDate, 'dd') } ${ format(newDate, 'MMMM') },${ format(newDate, 'EEEEEEE') }` ?? '');
      setValue('callTime', getUnixTime(parse(`${ values.dateValue } ${ values.hourValue }:${ values.minuteValue }`, 'yyyy-MM-dd HH:mm', new Date())));
  
      // if (onClose) { onClose(true); }
    }; */
    useEffect(() => {
        const newDate = parse(`${values.dateValue}`, 'yyyy-MM-dd', new Date());
        // eslint-disable-next-line no-constant-binary-expression
        setValue('dateString', `${values.hourValue}:${values.minuteValue}, ${format(newDate, 'dd')} ${format(newDate, 'MMMM')},${format(newDate, 'EEEEEEE')}`);
        setValue('callTime', getUnixTime(parse(`${values.dateValue} ${values.hourValue}:${values.minuteValue}`, 'yyyy-MM-dd HH:mm', new Date())));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.dateValue, values.hourValue, values.minuteValue]);
    return (_jsx(Form.Group, { controlId: controlId, className: error ? ' error-component' : '', children: _jsx(Row, { children: _jsxs(Col, { children: [label && (_jsx(Form.Label, { className: `label mb-0 pt-1 ${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsxs("div", { className: 'multi-date-time-design', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), _jsx("div", { className: 'edit-line', children: _jsxs("div", { className: 'selectors', children: [_jsx("div", { className: 'day', children: _jsx(Select, { label: '\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0434\u0435\u043D\u044C', hideLabel: true, required: true, options: optionsDate, name: 'dateValue', placeholder: '\u0434\u0435\u043D\u044C', zIndex: 1100 }) }), _jsx("div", { className: 'hour', children: _jsx(Select, { label: '\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0447\u0430\u0441', hideLabel: true, required: true, options: optionsHours, name: 'hourValue', placeholder: '\u0447\u0430\u0441', zIndex: 1100 }) }), _jsx("div", { className: 'minute', children: _jsx(Select, { label: '\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043C\u0438\u043D\u0443\u0442\u044B', hideLabel: true, required: true, options: optionsMinutes, name: 'minuteValue', placeholder: '\u043C\u0438\u043D\u0443\u0442\u044B', zIndex: 1100 }) })] }) })] }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: error !== null && error !== void 0 ? error : ' ' }) })] }) }) }));
};
export default DateTimeSelector;
