var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
// import { ErrorInterface } from '../../lib/globalApi';
import { fillingClientData } from './validation';
import { orderActions } from './store/orderStore';
import UniversalModalForm from './UniversalModalForm';
import { SimpleInput } from '../core';
import { clientActions } from './store/clientStore';
import { validateField } from '../core/components/validation';
import ym from 'react-yandex-metrika';
const ConfirmOrderContentPanel = () => {
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const inputRef = useRef();
    const handleClose = () => {
        setValue('smsCode', null);
        stateAppActions.setAppStateOrder(0);
    };
    const createOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(values === null || values === void 0 ? void 0 : values.orderId)) {
            yield clientActions.updateClient({ firstName: values.firstName, lastName: values === null || values === void 0 ? void 0 : values.lastName }, values.token);
            ym('reachGoal', 'entering_a_name');
            // Обрати внимание - если id заявки уже есть, повторно не создаём
            const createOrderResponse = yield orderActions.createOrderProc(values);
            if (createOrderResponse.id) {
                stateAppActions.setAppStateOrder(5);
            }
            else {
                stateAppActions.setAppStateOrder(6); // улетаем на окно ошибки
            }
        }
    });
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    const disabledButton = validateField('firstName', values, fillingClientData);
    return (_jsx(UniversalModalForm, { headerText: '\u041A\u0430\u043A \u043A \u0432\u0430\u043C \u043E\u0431\u0440\u0430\u0449\u0430\u0442\u044C\u0441\u044F?', detailHeader: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043C\u044F, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430', handleClose: handleClose, children: _jsxs(Row, { children: [_jsx(Col, { md: 12, children: _jsx(SimpleInput, { label: '\u0418\u043C\u044F', name: 'firstName', placeholder: '\u0418\u043C\u044F', required: true, autoFocus: true, ref: inputRef, validationSchema: fillingClientData, shouldValidate: (values === null || values === void 0 ? void 0 : values.firstName) && (values === null || values === void 0 ? void 0 : values.firstName.length) > 2 }) }), _jsx(Col, { md: 12, children: _jsx("div", { className: 'repeat', children: _jsx(Button, { className: `yellow-button${disabledButton ? ' disabledButton' : ' yellow-not-disabled'}`, variant: 'primary', onClick: createOrder, disabled: disabledButton, children: "\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437" }) }) })] }) }));
};
export default inject('clientStore')(observer(ConfirmOrderContentPanel));
