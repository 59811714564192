import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import { 
// IconType,
Header, CloseV2Icon, } from '..';
const MobileModalForm = (props) => {
    const { label, 
    // iconType,
    error, required, onClose, children, labelBeforeContent, noHeader, showCloseButton, className, } = props;
    const handleClose = () => { };
    useEffect(() => { }, []);
    return (_jsxs(Modal, { show: true, onHide: handleClose, className: classNames('mobile-modal-form', className), children: [!noHeader
                && (_jsx(Header, { returnNumber: null, header: label, noReturn: true, gradient: true, onCloseClick: onClose, noShadow: true })), !!showCloseButton
                && (_jsx("div", { className: 'close-button-on-modal', children: _jsx(CloseV2Icon, { onClick: onClose }) })), labelBeforeContent
                && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${
                    // iconType ? ' margin-left-label' : '' }${
                    error ? ' error-color-font' : ''}`, children: labelBeforeContent })), children] }));
};
export default MobileModalForm;
