import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { inject, observer } from 'mobx-react';
// import { useFormContext } from 'react-hook-form';
import ym from 'react-yandex-metrika';
// import { ReferenceStore } from './store/referenceStore';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
// import { OfficeStore } from '../map/store/officeStore';
import { SuccessIcon } from '../core';
// import { HookFormResponse } from './api/order';
/* export interface SuccessfulSuccessModalPanelProps {
  referenceStore?: ReferenceStore,
  // officeStore?: OfficeStore,
} */
const SuccessfulSuccessContent = () => {
    // const { watch } = useFormContext(); // retrieve all hook methods
    // const values: HookFormResponse = watch();
    useEffect(() => {
        ym('reachGoal', 'application_accepted');
    }, []);
    return (_jsx(Modal, { show: true, centered: true, children: _jsxs("div", { className: 'success_form text-center', children: [_jsx("div", { className: 'picture', children: _jsx(SuccessIcon, {}) }), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'avtoapp_color_font_34_400', children: "\u0417\u0430\u044F\u0432\u043A\u0430 \u043F\u0440\u0438\u043D\u044F\u0442\u0430" }) }), _jsx(Row, { className: 'text2', children: _jsx(Col, { xs: 12, className: 'font-14-400-black-noSpacing', children: "\u041D\u0430\u0448 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442 \u0441\u0432\u044F\u0436\u0435\u0442\u0441\u044F \u0441 \u0412\u0430\u043C\u0438 \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 10 \u043C\u0438\u043D\u0443\u0442" }) }), _jsx(Row, { className: 'button_success', children: _jsxs(Col, { children: [_jsx(Button, { className: 'next-button bottom_props', variant: 'outline-primary', onClick: () => {
                                    stateAppActions.setAppStateOrder(0);
                                }, children: "\u041E\u041A" }), ' '] }) })] }) }));
};
export default SuccessfulSuccessContent;
