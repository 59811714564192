var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import InputGroup from 'react-bootstrap/InputGroup';
// import { ObjectSchema } from 'yup';
import React from 'react';
import { CloseIcon } from '..';
import { iconSelector } from '../icons/iconSelector';
// import { HookFormResponse } from '../../order/api/order';
import { validateField } from './validation';
const SimpleInput = (props, ref) => {
    const { label, required, controlId, name, iconType, validationSchema, shouldValidate, className, error, disabled, autoFocus, placeholder, onClear } = props, rest = __rest(props, ["label", "required", "controlId", "name", "iconType", "validationSchema", "shouldValidate", "className", "error", "disabled", "autoFocus", "placeholder", "onClear"]) // mRef,
    ;
    const { control, getValues } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const inputGroupComponent = (field) => {
        return (_jsxs(InputGroup, { children: [_jsx(Form.Control, Object.assign({}, field, rest, { placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, 
                    // value={ options.find(v => v.value === field.value) }
                    onChange: (e) => {
                        field.onChange(e.target.value);
                    }, className: `simple-edit ${errorLocal ? 'error-component error-border error-color-font' : ''}`, disabled: disabled, autoFocus: autoFocus, ref: ref })), onClear
                    && (_jsx("div", { className: 'selector_box selected_box close-button', children: _jsx(CloseIcon, { onClick: () => { onClear(); } }) }))] }));
    };
    return (_jsxs(Form.Group, { controlId: controlId, className: `componentRow ${className}`, children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    if (iconType) {
                        return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                    }
                    return (_jsx("div", { className: 'inputDesign', children: inputGroupComponent(field) }));
                } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: errorLocal !== null && errorLocal !== void 0 ? errorLocal : ' ' }) })] }));
};
export default inject('referenceStore')(observer(React.forwardRef(SimpleInput)));
