var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ModalForm, } from '..';
import { referenceActions } from '../../order/store/referenceStore';
const BodyTypeSelectorModal = (props) => {
    const { watch, setValue } = useFormContext();
    const values = watch();
    const [ref78, setRef78] = useState([]);
    const { name, onClose } = props, rest = __rest(props, ["name", "onClose"]);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        const ref78Local = yield referenceActions.getRef(78);
        setRef78(ref78Local);
    });
    useEffect(() => {
        loadData();
    }, []);
    const handleClose = () => {
        if (onClose) {
            onClose(true);
        }
    };
    return (_jsxs(ModalForm, Object.assign({ label: '\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0442\u0438\u043F \u0432\u0430\u0448\u0435\u0433\u043E \u0430\u0432\u0442\u043E', onClose: () => { onClose(false); }, 
        // labelBeforeContent='Укажите время'
        className: 'body-type-modal' }, rest, { children: [_jsx(Row, { className: 'text1', children: _jsx(Col, { md: 12, children: "\u041C\u044B \u0441\u043C\u043E\u0436\u0435\u043C \u043F\u043E\u0434\u043E\u0431\u0440\u0430\u0442\u044C \u0442\u0435\u0445\u043D\u0438\u043A\u0443 \u0434\u043B\u044F \u0412\u0430\u0448\u0435\u0433\u043E \u0442\u0438\u043F\u0430 \u0430\u0432\u0442\u043E" }) }), _jsx(Row, { children: ref78.map((v) => {
                    return (_jsx(Col, { md: 4, sm: 6, xs: 6, className: 'text-center}', onClick: () => {
                            setValue(name, v.id);
                            setValue('wheelLockRefValueName', v.name);
                            handleClose();
                        }, children: _jsxs("div", { className: `text-center body-type-box${values[name] === v.id ? ' selected_item_box' : ''}`, children: [(v === null || v === void 0 ? void 0 : v.string2) && (_jsx("img", { src: v === null || v === void 0 ? void 0 : v.string2, alt: v.name })), v.name] }) }, v.id));
                }) })] })));
};
export default BodyTypeSelectorModal;
