import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useForm, FormProvider, } from 'react-hook-form';
import { format } from 'date-fns';
import LeftSidePanel from './LeftSidePanel';
import Map from '../map';
import MenuPanel from '../menu/MenuPanel';
import RightSidePanel from './RightSidePanel';
import PhonePanel from './PhonePanel';
const PrimaryPanel = (props) => {
    const params = new URLSearchParams(window.location.search);
    const getDebugParam = () => {
        if (params.get('user') === 'gk') {
            return {
                // firstName: 'Григорий',
                lastName: 'Кузнецов',
                phoneNumber: '+79267765897',
                // localTime: '12:00',
                // time: '1677576600',
                // orderId: '6e949b2e-af7d-4618-96c3-24ff74db866f',
                // date: new Date('2023-03-01'),
                // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzkyNjc3NjU4OTciLCJleHAiOjE3MzkzMDYwMDZ9.zbpxKwFUD_ydADB0YZc2nbv5Nuh4jcpUH2GPYo08hwjGov54beYDawXyN9jPRHTkrfz2vwnWCKYQl5Qt0XRofw',
                // serviceCode: 'callTowTruck',
                address: 'Олонецкая ул., 4, Москва, Россия, 127273',
                // fiasId: "01502b14-d0d6-4656-8262-6e310ba6efc0",
                lat: 55.8524,
                lng: 37.6192,
                deliveryLatitude: 55.848227939248126,
                deliveryLongitude: 37.61933226138353,
                //  "availabilitySts": true,
                //  "timezone": "Europe/Moscow",
                //  "timezoneOffset": 180,
                // "vehicleId": "27f53831-bf4c-4755-8820-f0ffc6717fe7",
                //   "vehicleNumber": "Т712ТТ123",
                //   "vehicleVin": "11111111111111111",
                //  "vehicleForeign": false,
                callTime: 1687186665,
                //  "sourceRefValueId": 145,
                deliveryAddress: 'Березовая аллея, 5А',
                // towTruckTypeRefValueId: 166,
                // cost: 4000.0000,
                paymentReceiptRequired: false,
                bodyTypeRefValueId: 375,
            };
        }
        if (params.get('user') === 'ak') {
            return {
                firstName: 'Алексей',
                lastName: 'Киликеев',
                phoneNumber: '+79163005252',
                token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzkxNjMwMDUyNTMiLCJleHAiOjE3Mzg4NTU5NDB9.SU-mykuyJ_A9uZ37wPqMwjutkeXPKDawOA6lr_TVxcbp38Mrjx6MQP2IjYK5w2BnGkuLNLVFJplBT_pIr8G3ZA',
            };
        }
        if (params.get('user') === 'df') {
            return {
                firstName: 'Дмитрий',
                lastName: 'Фёдоров',
                phoneNumber: '+79636967530',
                token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzk2MzY5Njc1MzAiLCJleHAiOjE3Mzk1MjI3Njh9.S0MJ-6tWnsbT71TUm0iEw4dqLxTAc0SopC85HDoxqDPTfFTEMUFuiXdwVqq2bMmLR9mjALUHoOk07qRuhinleQ',
            };
        }
        return {};
    };
    const methods = useForm({
        defaultValues: Object.assign(Object.assign({}, getDebugParam()), { serviceId: 13, urgentRefValueId: 40, dateString: `${format(new Date(), 'dd-MM-yyyy')} (сегодня)`, isWheelLock: false, wheelLockRefValueId: 55, 
            // для управления тенью зададим сразу scrollY =0
            scrollY: 0, minimized: false, utmCampaign: params.get('utm_campaign'), utmContent: params.get('utm_content'), utmMedium: params.get('utm_medium'), utmSource: params.get('utm_source'), utmTerm: params.get('utm_term') }),
        mode: 'onChange',
    });
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("div", { className: 'main_container_inner', children: [_jsxs(MenuPanel, { children: [" ", props === null || props === void 0 ? void 0 : props.children, " "] }), _jsx(PhonePanel, {}), _jsx(Map, {}), _jsx(LeftSidePanel, {}), _jsx(RightSidePanel, {})] }) })));
};
export default inject('stateAppStore')(observer(PrimaryPanel));
