import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { PhoneInput } from '../core';
import { validateField } from '../core/components/validation';
import { fillingPhone } from './validation';
import UniversalModalForm from './UniversalModalForm';
import ym from 'react-yandex-metrika';
const PhonePanelContent = () => {
    const { watch, setValue, getValues } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [oldPhone, setOldPhone] = useState(null);
    // const params = new URLSearchParams(window.location.search);
    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(false);
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const handleSave = () => {
        setValue('smsAlreadySend', false);
        stateAppActions.setAppStateOrder(2);
    };
    useEffect(() => {
        // Если есть токен, то сразу переходим в форму ввода СМС
        // для отладки использую
        // возможно, на бою надо этот useEffect убрать
        if (values.token) {
            setValue('smsAlreadySend', false);
            stateAppActions.setAppStateOrder(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClose = () => {
        setValue('phoneNumber', oldPhone);
        stateAppActions.setAppStateOrder(0);
    };
    useEffect(() => {
        setOldPhone(values === null || values === void 0 ? void 0 : values.phoneNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (token) {
            setValue('captchaToken', token);
            handleSave();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    const disabledButton = validateField('phoneNumber', getValues(), fillingPhone);
    const xx = () => {
        return (_jsxs(_Fragment, { children: ["\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0432\u0448\u0438\u0441\u044C, \u0432\u044B \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C \u0441 ", _jsx("span", { className: 'link-style', children: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F\u043C\u0438" })] }));
    };
    return (_jsx(UniversalModalForm, { headerText: '\u0412\u043E\u0439\u0442\u0438 \u0434\u043B\u044F \u0437\u0430\u043A\u0430\u0437\u0430', detailHeader: xx(), handleClose: handleClose, availableConditions: true, children: _jsxs(Row, { children: [_jsxs("div", { className: 'sms_line sms_line_reverse', children: [_jsx("div", { children: _jsx(PhoneInput, { label: '\u0422\u0435\u043B\u0435\u0444\u043E\u043D', name: 'phoneNumber', 
                                // iconType='phone'
                                // shouldValidate={ true }
                                // validationSchema={ fillingClientData }
                                autoFocus: true, hideErrorSpace: true }) }), _jsx("div", { className: 'repeat', children: _jsx(Button, { className: `yellow-button${disabledButton ? ' disabledButton' : ' yellow-not-disabled'}`, 
                                // variant='primary'
                                onClick: () => {
                                    // setVisible(true);
                                    if (token) {
                                        handleSave();
                                    }
                                    else {
                                        ym('reachGoal', 'send_sms_and_get_code');
                                        setVisible(true);
                                    }
                                }, disabled: disabledButton, children: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043A\u043E\u0434" }) })] }), visible && (_jsx(InvisibleSmartCaptcha, { sitekey: process.env.YANDEX_CAPTCHA_KEY, onSuccess: setToken, onChallengeHidden: handleChallengeHidden, visible: visible }))] }) }));
};
export default inject('calculatePriceStore')(observer(PhonePanelContent));
