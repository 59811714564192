import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@react-hooks-library/core';
import SummaryPanel from './SummaryPanel';
import SummaryPricePanel from './SummaryPricePanel';
import OffCanvas from '../core/visualComponents/Offcanvas';
const UniversalModalForm = (props) => {
    const { children, headerText, detailHeader, handleClose, availableConditions, } = props;
    // const { watch } = useFormContext(); // retrieve all hook methods
    // const values: HookFormResponse = watch();
    const [showConditions, setShowConditions] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (_jsxs("div", { className: 'modal_phone_form', children: [_jsxs("div", { className: 'modal_phone_form_block', children: [!isMobile && (_jsx(Row, { children: _jsxs(Col, { className: 'avtoapp_color_header_modal_font_32 centered-text', children: [headerText, _jsx(FontAwesomeIcon, { icon: faXmark, className: 'pull-right font-14-500-grey-noSpacing header_button', onClick: handleClose })] }) })), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'font-14-500-gray centered-text', onClick: () => setShowConditions(!!availableConditions), children: detailHeader }) }), children] }), _jsxs("div", { className: 'modal_phone_form_block', children: [_jsx(SummaryPricePanel, {}), _jsx(SummaryPanel, {})] }), showConditions
                && (_jsx(OffCanvas, { onClose: () => setShowConditions(false) }))] }));
};
export default UniversalModalForm;
