import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Версия Селектора для мобильный устройств
// Пока в сторону отложили
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import { ArrowRightIcon, ExpandArrowIcon, } from '..';
import SimpleSelectMobileModal from './SimpleSelectMobileModal';
import { validateField } from '../components/validation';
import { iconSelector } from '../icons/iconSelector';
const SimpleSelectMobile = (props) => {
    const { label, options, required, error, controlId, name, iconType, validationSchema, shouldValidate, noPaddings, hideLabel, } = props;
    const [showOptions, setShowOptions] = useState(false);
    const { control, getValues } = useFormContext();
    const handleClose = () => {
        setShowOptions(false);
    };
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema)
        ? validateField(name, getValues(), validationSchema) : null);
    return (_jsxs(_Fragment, { children: [_jsxs(Form.Group, { controlId: controlId, children: [label && !hideLabel && (_jsx(Form.Label, { className: `label mb-0 pt-1 ${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                            var _a, _b;
                            return (_jsxs("div", { className: 'inputDesign', children: [iconType
                                        && (_jsx("div", { className: 'icon', children: iconSelector(iconType) })), _jsx(Row, { className: classNames('box_row', noPaddings ? 'no_paddings' : ''), onClick: (event) => {
                                            event.preventDefault();
                                            setShowOptions((prev) => !prev);
                                        }, children: _jsxs(Col, { className: classNames('selector_box', 'selected_box'), children: [(_b = (_a = options.find((v) => v.value === field.value)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ' __.__.__ ', showOptions
                                                    ? _jsx(ExpandArrowIcon, {})
                                                    : _jsx(ArrowRightIcon, {})] }) })] }));
                        } }), error && (_jsx("div", { className: 'error-container', children: _jsx("div", { children: error }) }))] }), showOptions
                && (_jsx(SimpleSelectMobileModal, { options: options, name: name, label: label, 
                    // iconType={ iconType }
                    onClose: handleClose }))
            /* && (
              <Row className='selector_box_row'>
                { options.map((v:OptionItemType) => {
                  return (
                    <Col
                      md='auto'
                      key={ v.value }
                      className={ `text-center align-items-center selector_box_value ${ field.value === v.value ? 'selected' : '' }` }
                      onClick={ (event) => {
                        event.preventDefault();
                        field.onChange(v.value);
                        setShowOptions(false);
                      } }
                    > { v.label }
                    </Col>
                  );
                }) }
              </Row>
            ) */
        ] }));
};
export default inject('referenceStore')(observer(SimpleSelectMobile));
