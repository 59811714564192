import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import { Header } from '../core';
import ConfirmOrderContentPanel from './ConfirmOrderContentPanel';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
const ConfirmOrderModalPanel = () => {
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => { stateAppActions.setAppStateOrder(0); };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 0, header: '\u0414\u043E\u0431\u0440\u044B\u0439 \u0434\u0435\u043D\u044C!', noNavigate: !isMobile, gradient: true, onCloseClick: () => { setValue('minimized', !values.minimized); } }), _jsx(ConfirmOrderContentPanel, {})] })) : (_jsx(Modal, { show: true, onHide: handleClose, centered: true, className: 'modal-opacity', children: _jsx(ConfirmOrderContentPanel, {}) })) }));
};
export default ConfirmOrderModalPanel;
