import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
// import { useMediaQuery } from '@react-hooks-library/core';
import React from 'react';
import { SwitchOffIcon, SwitchOnIcon, HelpIcon, } from '..';
const CheckBox = (props) => {
    const { label, name, helpTitle } = props;
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    // const isMobile = useMediaQuery('(max-width: 768px)');
    const handleForeignClick = () => {
        setValue(name, !values[name]);
    };
    return (_jsxs("div", { className: 'checkbox-div', children: [_jsxs("div", { className: 'label-div', children: [label, helpTitle
                        ? (_jsx("div", { "data-bs-toggle": 'tooltip', "data-bs-placement": 'top', title: helpTitle, children: _jsx(HelpIcon, {}) }))
                        : null] }), _jsx("div", { onClick: handleForeignClick, "aria-hidden": 'true', className: !values[name] ? 'off' : 'on', children: !values[name] ? _jsx(SwitchOffIcon, {}) : _jsx(SwitchOnIcon, {}) })] }));
};
export default inject('referenceStore')(observer(React.forwardRef(CheckBox)));
