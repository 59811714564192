import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { iconSelector } from '../icons/iconSelector';
import { validateField } from '../components/validation';
const SimpleSelect = (props) => {
    const { label, options, required, error, controlId, name, disabled, placeholder, iconType, validationSchema, shouldValidate, zIndex, hideLabel, } = props;
    const customStyles = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        control: (base, state) => (Object.assign(Object.assign({}, base), { background: '#F2F2F2', borderColor: error ? '#EB5757' : '#F2F2F2', borderWidth: error ? '1px' : '0px', height: '47px', boxShadow: null, '&:hover': {
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "red" : "blue"
            }, span: {
                borderColor: '#F2F2F2 !important',
            } })),
        menuPortal: provided => (Object.assign(Object.assign({}, provided), { zIndex: zIndex !== null && zIndex !== void 0 ? zIndex : 1001 })),
    };
    const { control, getValues } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema)
        ? validateField(name, getValues(), validationSchema) : null);
    const inputGroupComponent = (field) => {
        // if (name === 'hourValue') console.log(field);
        return (_jsx(Select, Object.assign({}, field, { value: field.value ? options.find((v) => v.value === field.value) : null, 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (e) => {
                var _a;
                field.onChange((_a = e === null || e === void 0 ? void 0 : e.value) !== null && _a !== void 0 ? _a : null);
            }, options: options, theme: (theme) => (Object.assign(Object.assign({}, theme), { 
                // borderRadius: 8,
                colors: Object.assign(Object.assign({}, theme.colors), { 
                    // primary25: '#F8F7FB',
                    primary: '#533B99' }) })), 
            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            menuPortalTarget: document.body, menuPosition: 'fixed', menuPlacement: 'auto', menuShouldScrollIntoView: false, styles: customStyles, isDisabled: disabled, className: `simple-edit ${error ? 'error-border -font' : ''}`, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, blurInputOnSelect: true, isSearchable: false })));
    };
    return (_jsx(Form.Group, { controlId: controlId, className: error ? ' error-component' : '', children: _jsx(Row, { children: _jsxs(Col, { children: [label && !hideLabel && (_jsx(Form.Label, { className: `label mb-0 pt-1 ${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                            if (iconType) {
                                return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                            }
                            return inputGroupComponent(field);
                        } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: error !== null && error !== void 0 ? error : ' ' }) })] }) }) }));
};
export default inject('referenceStore')(observer(SimpleSelect));
