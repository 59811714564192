import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
// import { useMediaQuery } from '@react-hooks-library/core';
import { Row } from 'react-bootstrap';
const SummaryPanel = () => {
    var _a, _b;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    // const isMobile = useMediaQuery('(max-width: 768px)');
    return (_jsxs("div", { className: 'summary-panel', children: [_jsxs("div", { className: 'first-line', children: [_jsxs("div", { className: 'div-bordered left', children: [_jsx(Row, { className: 'textLabel', children: "\u0412\u0430\u0448 \u0410\u0432\u0442\u043E\u043C\u043E\u0431\u0438\u043B\u044C" }), _jsx(Row, { className: 'textValue', children: values === null || values === void 0 ? void 0 : values.wheelLockRefValueName })] }), _jsxs("div", { className: 'div-bordered right', children: [_jsx(Row, { className: 'textLabel', children: "\u0422\u0438\u043F \u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440\u0430" }), _jsx(Row, { className: 'textValue', children: (values === null || values === void 0 ? void 0 : values.serviceId) === 13 ? 'Эвакуатор' : 'Манипулятор' })] })] }), _jsxs("div", { className: 'div-bordered left', children: [_jsx(Row, { className: 'textLabel', children: "\u041E\u0442\u043A\u0443\u0434\u0430 \u0437\u0430\u0431\u0440\u0430\u0442\u044C" }), _jsx(Row, { className: 'textValue', children: (_a = values === null || values === void 0 ? void 0 : values.address) !== null && _a !== void 0 ? _a : ' ' }), _jsx(Row, { className: 'textLabel', children: "\u041A\u0443\u0434\u0430 \u043E\u0442\u0432\u0435\u0437\u0442\u0438" }), _jsx(Row, { className: 'textValue', children: (_b = values === null || values === void 0 ? void 0 : values.deliveryAddress) !== null && _b !== void 0 ? _b : ' ' })] })] }));
};
export default SummaryPanel;
