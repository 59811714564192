import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
// import { useFormContext } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import ym from 'react-yandex-metrika';
// import { HookFormResponse } from '../../order/api/order';
import classNames from 'classnames';
import { stateAppActions } from '../../../lib/mobx/stateAppStore';
const Footer = (props) => {
    const { nextNumber, label, 
    // noNavigate,
    validation, disabled, onClickShowOffice, disabledShowOffice, onClick, variant, shadow, } = props;
    // const { watch } = useFormContext(); // retrieve all hook methods
    // const values: HookFormResponse = watch();
    // console.log('values?.scrollY=',values?.scrollY);
    return (_jsxs("div", { className: classNames('widget-footer', 'padding_main_layer', { shadow }), children: [onClickShowOffice
                && (_jsx(Row, { style: { marginTop: '10px' }, children: _jsx(Col, { children: _jsx(Button, { className: 'next-button show-on-map', variant: 'outline-primary', onClick: onClickShowOffice, disabled: disabledShowOffice, children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043D\u0430 \u043A\u0430\u0440\u0442\u0435" }) }) })), _jsx(Row, { children: _jsx(Col, { children: _jsx(Button, { className: `next-button${disabled ? ' disabledButton' : ''}`, variant: variant !== null && variant !== void 0 ? variant : 'outline-primary', onClick: () => {
                            if (((validation && validation()) || (!validation)) && nextNumber) {
                                if (nextNumber === 1) {
                                    ym('reachGoal', 'place_an_order');
                                }
                                stateAppActions.setAppStateOrder(nextNumber);
                            }
                            if (onClick) {
                                onClick();
                            }
                        }, disabled: disabled, children: label }) }) })] }));
};
export default Footer;
