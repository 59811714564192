import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import icon from './switchOff.svg';
/*
   // Обрати внимание - в строке
   <rect width='50' height='30' rx='15' transform='matrix(-1 0 0 1 51 0.5)' fill='#C7C7CC' />
   параметр fill='#C7C7CC'
   вынес в scss, чтоб управлять hover-эффектами
*/
export const SwitchOffSvgComponent = () => {
    return (_jsxs("svg", { width: '51', height: '33', viewBox: '0 0 51 33', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("rect", { width: '50', height: '30', rx: '15', transform: 'matrix(-1 0 0 1 51 0.5)' }), _jsx("g", { filter: 'url(#filter0_dd_16950_196532)', children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M16 29.5C8.26801 29.5 2 23.232 2 15.5C2 7.76801 8.26801 1.5 16 1.5C23.732 1.5 30 7.76801 30 15.5C30 23.232 23.732 29.5 16 29.5Z', fill: 'white' }) }), _jsx("defs", { children: _jsxs("filter", { id: 'filter0_dd_16950_196532', x: '0', y: '0.5', width: '32', height: '32', filterUnits: 'userSpaceOnUse', colorInterpolationFilters: 'sRGB', children: [_jsx("feFlood", { floodOpacity: '0', result: 'BackgroundImageFix' }), _jsx("feColorMatrix", { in: 'SourceAlpha', type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0', result: 'hardAlpha' }), _jsx("feOffset", { dy: '1' }), _jsx("feGaussianBlur", { stdDeviation: '1' }), _jsx("feColorMatrix", { type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' }), _jsx("feBlend", { mode: 'normal', in2: 'BackgroundImageFix', result: 'effect1_dropShadow_16950_196532' }), _jsx("feColorMatrix", { in: 'SourceAlpha', type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0', result: 'hardAlpha' }), _jsx("feOffset", { dy: '0.1' }), _jsx("feGaussianBlur", { stdDeviation: '0.15' }), _jsx("feColorMatrix", { type: 'matrix', values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' }), _jsx("feBlend", { mode: 'normal', in2: 'effect1_dropShadow_16950_196532', result: 'effect2_dropShadow_16950_196532' }), _jsx("feBlend", { mode: 'normal', in: 'SourceGraphic', in2: 'effect2_dropShadow_16950_196532', result: 'shape' })] }) })] }));
};
export default icon;
