var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, memo, useCallback, useEffect, useMemo, } from 'react';
// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import { useLoadScript, GoogleMap, Marker, DirectionsRenderer, DirectionsService, } from '@react-google-maps/api';
import { useFormContext } from 'react-hook-form';
import { COORDINATES_MSK_CENTER, DEFAULT_ZOOM, DIRECTIONS_RENDERER_OPTIONS, MAP_CONTAINER_STYLE, MAP_OPTIONS, MAX_ZOOM, MIN_ZOOM, ROUTE_KEYS, } from './consts';
import { fetchAddressByCoordinates } from './utils';
import PinCarSVG from '../core/icons/pinCar';
import PinParkingSVG from '../core/icons/pinParking';
import { MinusIcon, PlusIcon, PositionArrowIcon, } from '../core/icons';
function Map() {
    const hostname = useMemo(() => { var _a, _b; return (_b = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '')) === null || _b === void 0 ? void 0 : _b.trim(); }, []);
    const { isLoaded } = useLoadScript({
        // Enter your own Google Maps API key
        // googleMapsApiKey: '', // process.env.GOOGLE_API_KEY,
        // googleMapsApiKey: process.env.GOOGLE_API_KEY,
        googleMapsApiKey: (hostname === 'localhost'
            || hostname === '127.0.0.1'
            // || hostname === 'evacuator-widget.dev.avtoapp.online'
            || hostname.indexOf('.loc') > 0
        // || hostname.indexOf('v00') > 0
        ) ? '' : process.env.GOOGLE_API_KEY,
        libraries: ['places', 'geometry', 'routes'],
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mapRef, setMapRef] = useState(null);
    const [center, setCenter] = useState(COORDINATES_MSK_CENTER);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [tempZoom, setTempZoom] = useState(DEFAULT_ZOOM);
    const [markers, setMarkers] = useState([undefined, undefined]);
    const [direction, setDirection] = useState(undefined);
    const [isSource, setIsSource] = useState(true);
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { lat, lng, deliveryLatitude, deliveryLongitude } = values;
    const handleCenter = useCallback((newCenter) => {
        setCenter(prevCenter => {
            // карта не реагирует на более мелкие изменения центра
            if (prevCenter.lat === newCenter.lat && prevCenter.lng === newCenter.lng) {
                return {
                    lat: newCenter.lat + 0.00001,
                    lng: newCenter.lng + 0.00001,
                };
            }
            return newCenter;
        });
    }, []);
    const updateStore = useCallback((point) => (coordinates, address, placeId) => {
        setValue(ROUTE_KEYS[point].lat, coordinates === null || coordinates === void 0 ? void 0 : coordinates.lat);
        setValue(ROUTE_KEYS[point].lng, coordinates === null || coordinates === void 0 ? void 0 : coordinates.lng);
        setValue(ROUTE_KEYS[point].address, address);
        setValue(ROUTE_KEYS[point].placeId, placeId);
    }, [setValue]);
    const handleMarkerDragEnd = useCallback((route, index) => (event) => __awaiter(this, void 0, void 0, function* () {
        if (!route)
            return;
        const eventLat = event.latLng.lat();
        const eventLng = event.latLng.lng();
        if (eventLat === undefined || eventLng === undefined) {
            return;
        }
        const coordinates = {
            lat: eventLat,
            lng: eventLng,
        };
        if (index === 0) {
            yield fetchAddressByCoordinates(coordinates, updateStore('source'));
            setMarkers([coordinates, route[1]]);
        }
        if (index === 1) {
            yield fetchAddressByCoordinates(coordinates, updateStore('target'));
            setMarkers([route[0], coordinates]);
        }
    }), [updateStore]);
    const pins = useMemo(() => markers.reduce((acc, marker, index) => {
        if ((marker === null || marker === void 0 ? void 0 : marker.lat) !== undefined && (marker === null || marker === void 0 ? void 0 : marker.lng) !== undefined) {
            acc.push(_jsx(Marker, { position: { lat: marker.lat, lng: marker.lng }, icon: index === 0 ? PinCarSVG : PinParkingSVG, draggable: true, onDragEnd: handleMarkerDragEnd(markers, index) }, `key_${marker.lat}-${marker.lng}`));
        }
        return acc;
    }, []), [markers, handleMarkerDragEnd]);
    const request = useMemo(() => {
        if (!markers[0] || !markers[1]) {
            return null;
        }
        return {
            origin: {
                lat: markers[0].lat,
                lng: markers[0].lng,
            },
            destination: {
                lat: markers[1].lat,
                lng: markers[1].lng,
            },
            travelMode: 'DRIVING',
        };
    }, [markers]);
    const handleClick = useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        const coordinates = e.latLng ? {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        } : undefined;
        if (isSource) {
            yield fetchAddressByCoordinates(coordinates, updateStore('source'));
            setMarkers([coordinates, markers[1]]);
            setIsSource(false);
        }
        else {
            yield fetchAddressByCoordinates(coordinates, updateStore('target'));
            setMarkers([markers[0], coordinates]);
            setIsSource(true);
        }
    }), [markers, updateStore, isSource]);
    /* const selectedOffice = useMemo(
      () => visibleOffices.find(office => office.id === selected),
      [selected, visibleOffices]
    ); */
    const handlePanToCurrentLocation = useCallback(e => {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                handleCenter(pos);
            });
        }
    }, [handleCenter]);
    const handlePlusZoom = useCallback(e => {
        e.preventDefault();
        if (tempZoom < MAX_ZOOM) {
            setZoom(tempZoom + 1);
            setTempZoom(tempZoom + 1);
        }
    }, [tempZoom]);
    const handleMinusZoom = useCallback(e => {
        e.preventDefault();
        if (tempZoom > MIN_ZOOM) {
            setZoom(tempZoom - 1);
            setTempZoom(tempZoom - 1);
        }
    }, [tempZoom]);
    const directionsCallback = useCallback((result, status) => {
        if (result !== null) {
            if (status === 'OK') {
                setDirection(result);
            }
            else {
                // eslint-disable-next-line no-console
                console.error('response: ', result);
            }
        }
    }, []);
    const loadHandler = useCallback(map => {
        setMapRef(map);
    }, []);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_b = (_a = values.region) === null || _a === void 0 ? void 0 : _a.center) === null || _b === void 0 ? void 0 : _b.lat) && ((_d = (_c = values.region) === null || _c === void 0 ? void 0 : _c.center) === null || _d === void 0 ? void 0 : _d.lng)) {
            handleCenter({
                lat: (_f = (_e = values.region) === null || _e === void 0 ? void 0 : _e.center) === null || _f === void 0 ? void 0 : _f.lat,
                lng: (_h = (_g = values.region) === null || _g === void 0 ? void 0 : _g.center) === null || _h === void 0 ? void 0 : _h.lng,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.region]);
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                handleCenter(pos);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // координата может быть ноль
        if (lat !== undefined && lng !== undefined) {
            setMarkers(prevState => {
                return [{ lat, lng }, prevState[1]];
            });
            handleCenter({ lat, lng });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    useEffect(() => {
        if (deliveryLatitude !== undefined && deliveryLongitude !== undefined) {
            setMarkers(prevState => {
                return [prevState[0], { lat: deliveryLatitude, lng: deliveryLongitude }];
            });
            handleCenter({ lat: deliveryLatitude, lng: deliveryLongitude });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryLatitude, deliveryLongitude]);
    if (!isLoaded) {
        return '';
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'map_box', children: _jsxs(GoogleMap, { onLoad: loadHandler, center: center, zoom: zoom, onClick: handleClick, options: MAP_OPTIONS, mapContainerStyle: MAP_CONTAINER_STYLE, children: [pins, !!request && (_jsx(DirectionsService, { options: request, callback: directionsCallback })), !!direction && (_jsx(DirectionsRenderer, { options: DIRECTIONS_RENDERER_OPTIONS, directions: direction }))] }) }), _jsxs("div", { className: 'map_ui', children: [_jsx("button", { type: 'button', className: 'map_ui_button map_ui_plus', onClick: handlePlusZoom, disabled: tempZoom >= MAX_ZOOM, "aria-label": 'plus', children: _jsx(PlusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button map_ui_minus', onClick: handleMinusZoom, disabled: tempZoom <= MIN_ZOOM, "aria-label": 'minus', children: _jsx(MinusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button', onClick: handlePanToCurrentLocation, "aria-label": 'to_current', children: _jsx(PositionArrowIcon, {}) })] })] }));
}
export default memo(Map);
