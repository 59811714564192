import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import { Header } from '../core';
import SmsPanelContent from './SmsPanelContent';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
const SmsModalPanel = () => {
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => { stateAppActions.setAppStateOrder(0); };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 0, header: '\u0412\u043E\u0439\u0442\u0438 \u0434\u043B\u044F \u0437\u0430\u043A\u0430\u0437\u0430', noNavigate: !isMobile, 
                    // gradient
                    onCloseClick: () => { setValue('minimized', !values.minimized); } }), _jsx(SmsPanelContent, {})] })) : (_jsx(Modal, { show: true, onHide: handleClose, centered: true, className: 'modal-opacity', children: _jsx(SmsPanelContent, {}) })) }));
};
export default SmsModalPanel;
