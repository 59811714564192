import { useEffect, useRef, useState } from 'react';
export function useScrollWithShadow(flag) {
    const scroller = useRef(null);
    const [positions, setPositions] = useState({
        top: false,
        bottom: false,
    });
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const instance = scroller.current;
        function updateScrollPosition() {
            const { scrollTop, clientHeight, scrollHeight } = instance;
            const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
            if (isBetween) {
                setPositions({ top: true, bottom: true });
                return;
            }
            const isTop = scrollTop === 0;
            const isBottom = clientHeight === scrollHeight - scrollTop;
            setPositions({ top: !isTop, bottom: !isBottom });
        }
        if (instance) {
            instance.addEventListener('scroll', updateScrollPosition, false);
            window.addEventListener('resize', updateScrollPosition);
            return function cleanup() {
                instance.removeEventListener('scroll', updateScrollPosition, false);
                window.addEventListener('resize', updateScrollPosition);
            };
        }
    }, []);
    useEffect(() => {
        const instance = scroller.current;
        if (instance) {
            const { scrollHeight, clientHeight } = instance;
            if (scrollHeight !== clientHeight) {
                setPositions(Object.assign(Object.assign({}, positions), { bottom: true }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flag]);
    return { scroller, positions };
}
