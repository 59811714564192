import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
// import ym from 'react-yandex-metrika';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { FailIcon } from '../core';
const FinallyErrorContentPanel = () => {
    useEffect(() => {
        // TODO Маркетинг в будущем хочет видеть ошибки.
        // пока оключил
        // ym('reachGoal', 'order_error');
    }, []);
    return (_jsx(Modal, { show: true, centered: true, children: _jsxs("div", { className: 'success_form text-center', children: [_jsx("div", { className: 'picture', children: _jsx(FailIcon, {}) }), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'avtoapp_color_font_34_400', children: "\u0423\u043F\u0441 !" }) }), _jsx(Row, { className: 'text2', children: _jsx(Col, { xs: 12, className: 'font-14-400-black-noSpacing', children: "\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0435\u0449\u0435 \u0440\u0430\u0437 \u0438\u043B\u0438 \u0437\u0430\u043A\u0430\u0436\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0443 \u043F\u043E \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443!" }) }), _jsx(Row, { className: 'button_success', children: _jsxs(Col, { children: [_jsx(Button, { className: 'next-button bottom_props', variant: 'primary', onClick: () => {
                                    stateAppActions.setAppStateOrder(0);
                                }, children: "\u041F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C \u0441\u043D\u043E\u0432\u0430" }), ' '] }) })] }) }));
};
export default FinallyErrorContentPanel;
