var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable,
// runInAction
 } from 'mobx';
import Cookies from 'js-cookie';
import orderController from './controller';
export const orderStore = observable({
    createOrderResponse: null,
    error: null,
});
export const orderActions = {
    createOrder: (request_1, ...args_1) => __awaiter(void 0, [request_1, ...args_1], void 0, function* (request, token = null) {
        /*  runInAction(() => {
          orderStore.appointmentEditInterface = request;
        }); */
        const response = yield orderController.createOrder(request, token);
        orderActions.handleError(response);
        if (!response.errorData) {
            orderStore.createOrderResponse = response.data;
        }
        /* if (!response.errorData) {
          orderStore.timesResponse = response.data;
        } */
        return response.data;
    }),
    createOrderProc: (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f;
        const hostname = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '').trim();
        let createOrderResponse = null;
        // Вообще ФИО будем указывать в проекте эвакуации ? В дизайне нет
        // clientActions.updateClient({ firstName: values?.firstName, lastName: values?.lastName }, values?.token);
        if (!(values === null || values === void 0 ? void 0 : values.orderId)) { // Обрати внимание - если id заявки уже есть, повторно не создаём
            createOrderResponse = yield orderActions.createOrder({
                serviceId: values.serviceId,
                address: values === null || values === void 0 ? void 0 : values.address,
                lat: values === null || values === void 0 ? void 0 : values.lat,
                lng: values === null || values === void 0 ? void 0 : values.lng,
                deliveryLatitude: values === null || values === void 0 ? void 0 : values.deliveryLatitude,
                deliveryLongitude: values === null || values === void 0 ? void 0 : values.deliveryLongitude,
                urgentRefValueId: values === null || values === void 0 ? void 0 : values.urgentRefValueId,
                wheelLockRefValueId: (values === null || values === void 0 ? void 0 : values.isWheelLock) ? values === null || values === void 0 ? void 0 : values.wheelLockRefValueId : 55,
                callTime: values === null || values === void 0 ? void 0 : values.callTime,
                deliveryAddress: values === null || values === void 0 ? void 0 : values.deliveryAddress,
                paymentReceiptRequired: values === null || values === void 0 ? void 0 : values.paymentReceiptRequired,
                bodyTypeRefValueId: values === null || values === void 0 ? void 0 : values.bodyTypeRefValueId,
                comment: values === null || values === void 0 ? void 0 : values.comment,
                // Маркетинговые  записи:
                utmCampaign: (_b = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _b !== void 0 ? _b : Cookies.get('avtoapp_utm_term'),
                utmContent: (_c = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _c !== void 0 ? _c : Cookies.get('avtoapp_utm_content'),
                utmMedium: (_d = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _d !== void 0 ? _d : Cookies.get('avtoapp_utm_medium'),
                utmSource: (_e = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _e !== void 0 ? _e : Cookies.get('avtoapp_utm_source'),
                utmTerm: (_f = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _f !== void 0 ? _f : Cookies.get('avtoapp_utm_term'),
                sourceRefValueId: 470, // сайт
                sourceDomain: hostname,
            }, values === null || values === void 0 ? void 0 : values.token);
            return createOrderResponse;
        }
        return null;
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            orderStore.error = errorData;
            throw errorData;
        }
    }),
    clearError: action(() => {
        orderStore.error = null;
    }),
};
