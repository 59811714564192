var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// import Select from 'react-select';
// import Form from 'react-bootstrap/Form';
// import { useFormContext, Controller } from 'react-hook-form';
// import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from '@react-hooks-library/core';
import { SimpleSelect, SimpleSelectMobile, } from '..';
const Select = (props) => {
    const { label, options, required, error, controlId, name, disabled, placeholder, iconType } = props, rest = __rest(props, ["label", "options", "required", "error", "controlId", "name", "disabled", "placeholder", "iconType"]);
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile
            ? (_jsx(SimpleSelectMobile, Object.assign({}, rest, { controlId: controlId, label: label, error: error, required: required, options: options, name: name, iconType: iconType })))
            : (_jsx(SimpleSelect, Object.assign({}, rest, { controlId: controlId, label: label, error: error, required: required, options: options, name: name, placeholder: placeholder, disabled: disabled, iconType: iconType }))) }));
};
export default Select;
