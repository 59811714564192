import { makeRequest } from '../../../lib/api';
const OPEN_API_PATH = '/v1/open';
const AUTH_PATH = '/v3/client/auth';
// const SCHEDULER_PATH = '/v2/client/scheduler';
const CLIENT_PATH = '/v1/client';
// const PRICE_PATH = '/v1/open/prices/calculate/evacuation';
class orderController {
    constructor() {
        Object.defineProperty(this, "getUniversalReference", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (refId) => {
                return makeRequest('get', `${OPEN_API_PATH}/ref_value/${refId}`);
            }
        });
        Object.defineProperty(this, "getRegions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return makeRequest('get', `${OPEN_API_PATH}/seo_pages`);
            }
        });
        Object.defineProperty(this, "getGeoPlaces", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                // return makeRequest('get', `${ OPEN_API_PATH }/gmaps/places?countryCode=${ request.countryCode ?? 'ru' }&input=${ request.input }`);
                return makeRequest('get', `${OPEN_API_PATH}/ymaps/places?input=${request.input}`);
            }
        });
        Object.defineProperty(this, "getGeoPlaceDirect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                // return makeRequest('get', `${ OPEN_API_PATH }/gmaps/direct?placeId=${ request.placeId }`);
                return makeRequest('get', `${OPEN_API_PATH}/ymaps/direct?address=${request === null || request === void 0 ? void 0 : request.address}`);
            }
        });
        Object.defineProperty(this, "getGeoPlaceReverse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('get', `${OPEN_API_PATH}/gmaps/reverse?lat=${request.lat}&lng=${request.lng}`);
            }
        });
        Object.defineProperty(this, "sendSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/captcha`, request);
            }
        });
        Object.defineProperty(this, "confirmSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/confirm/captcha`, request);
            }
        });
        Object.defineProperty(this, "getClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (token = null) => {
                return makeRequest('get', `${CLIENT_PATH}/profile`, {}, {}, 200, token);
            }
        });
        Object.defineProperty(this, "updateClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('put', `${CLIENT_PATH}/supplement`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "createOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('post', `${CLIENT_PATH}/orders`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "calculatePrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/calculate/evacuation`, request);
            }
        });
    }
}
export default new orderController();
